import React, { useState, useEffect } from 'react';

function App() {
  const [transaktioner, setTransaktioner] = useState([]);

  useEffect(() => {
    fetch('/Transaktioner.json')
      .then((response) => response.json())
      .then((data) => setTransaktioner(data.transaktioner));
  }, []);

  return (
    <div>
      <h1>Transaktioner</h1>
      {transaktioner.map((transaktion) => (
        <div key={transaktion.id} style={{ border: '1px solid #ccc', margin: '10px', padding: '10px' }}>
          <h3>{transaktion.beskrivning} {transaktion.id} ({transaktion.kategori})</h3>
          <p>Datum: {transaktion.datum}</p>
          <p>Belopp: {transaktion.belopp} {transaktion.valuta}</p>
          <p>Användare: {transaktion.användare.namn} {transaktion.användare.id} - {transaktion.användare.roll}</p>
          <p>Betald: {transaktion.betald ? 'Ja' : 'Nej'}</p>
          <h3>Konton</h3>
          {transaktion.konton.map((konto) => (
            <div key={konto.konto_id} style={{ border: '1px solid #ccc', marginBottom: '10px' }}>
              <p>Konto-ID: {konto.konto_id}</p>
              <p>Konto-typ: {konto.konto_typ}</p>
              <p>Saldo: {konto.saldo} {konto.valuta}</p>
              <p>Aktiv: {konto.aktiv ? 'Ja' : 'Nej'}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default App;
